import { useMediaQuery } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Image from "next/image";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Footer } from "../../components/Footer";
import GoogleButton from "../../components/oidc/GoogleButton";
import { useUserContext } from "../../context/UserContext";
import { useOurRouter } from "../../hooks/useOurRouter";
import { reclaim } from "../../reclaim-api/index";
import { QueryState } from "../../types/query";
import {
  getLocalStorage,
  getSessionStorage,
  removeLocalStorage,
  removeSessionStorage,
} from "../../utils/local-storage";
import { browser } from "../../utils/platform";
import LandingLayout from "../LandingLayout";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "grid",
      gridTemplateColumns: `auto`,
      gridTemplateRows: "auto min-content",
      gridTemplateAreas: '"main" "footer"',
      height: "100%",
      overflow: "hidden",
    },
    main: {
      gridArea: "main",
    },
    footer: {
      gridArea: "footer",
    },
    hero: {
      alignContent: "center",
      flexDirection: "column",
      position: "relative",
      padding: theme.spacing(3, 3, 8),
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        justifyContent: "center",
        padding: theme.spacing(8, 6, 12),
      },
    },
    heroImg: {
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "8em",
      },
    },
    heroText: {
      maxWidth: "600px",
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "720px",
      },
    },
    body: {
      margin: theme.spacing(4, 0),
    },
    button: {
      margin: theme.spacing(1, 0),
      maxWidth: 360,
      boxShadow: theme.shadows[4],
    },
    img: {
      display: "block",
      height: "5em",
      width: "5em",
      [theme.breakpoints.up("md")]: {
        height: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "28em",
        width: "100%",
      },
    },
    growBox: {
      flex: "0 1 auto", //keep to fix safari bug
    },
    o365Callout: {
      border: "1px solid rgba(0,0,0,.125)",
      borderRadius: 4,
      marginTop: theme.spacing(5),
      padding: theme.spacing(2),
      "& a": {
        color: theme.colors.logo.blurple,
      },
    },
    fullWidth: {
      flex: "0 1 auto", //keep to fix safari bug
      width: "100%",
    },
  }),
  { index: 2, name: "LoginTemplate" }
);

export type LoginLayoutProps = {
  title: string;
  displayLoginButton: boolean;
  loginButtonLabel?: string;
  officeNotice?: boolean;
};

export const LoginLayout: React.FC<LoginLayoutProps> = ({
  title,
  displayLoginButton,
  loginButtonLabel = "Log in",
  officeNotice,
  children,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const medium = useMediaQuery(theme.breakpoints.down("md"));

  const router = useOurRouter<{ reason: string; state: QueryState }>();

  const [{ user, isAuthenticated, status }] = useUserContext();

  const [error, setError] = useState<string | undefined>();

  const urlParams = useMemo(() => new URLSearchParams(browser().isBrowser ? window.location.search : ""), []);

  useEffect(() => {
    if (!router.query?.reason) return;
    const reason = router.query.reason;

    setError(
      "USER_DISABLED" === reason
        ? "Calendar for this email address is linked to another account. Please sign in using your other Google login."
        : "User not authorized."
    );
  }, [router.query?.reason]);

  // Redirect if user is already authenticated
  useEffect(() => {
    if (!router.isReady || !isAuthenticated) return;

    removeLocalStorage("auth.reauth");
    removeLocalStorage("auth.attempts");

    const slackTeamId = urlParams.get("slackTeamId");
    const slackUserId = urlParams.get("slackUserId");

    // the check for "://" prevents arbitrary redirection (Open Redirect)
    const oauthRedirect = router.query.state?.redirect;
    const redirect =
      !!oauthRedirect && !oauthRedirect.includes("://") ? oauthRedirect : getSessionStorage("auth.redirect");
    const hasSharedHabit = !!getLocalStorage("habits.sharedPreset");

    if (redirect) {
      removeSessionStorage("auth.redirect");
    }

    // connect slack user
    if (slackUserId && slackTeamId) {
      reclaim.slack.link(slackUserId, slackTeamId).catch((reason) => {
        if (404 === reason.status) {
          console.log("Slack user not found, redirecting", reason);
          reclaim.slack.authRedirect(slackTeamId);
          return;
        } else {
          console.error("Failed to link slack user", reason);
        }
      });
    }

    let pathname = "/onboarding/welcome";

    if (user?.onboarded) {
      if (hasSharedHabit) pathname = "/habits";
      else if (redirect) pathname = redirect;
      else if (medium) pathname = "/stats";
      else pathname = "planner";
    }

    void router.push({
      pathname,
      query: router.query,
    });
  }, [isAuthenticated, medium, router, urlParams, user?.onboarded]);

  const handleClick = useCallback(
    (provider: string) => {
      removeLocalStorage("auth.reauth");
      removeLocalStorage("auth.attempts");

      const storedUser = getLocalStorage("auth.user");
      const ref = getLocalStorage("auth.ref");
      const utm = getLocalStorage("auth.utm");

      const slackTeamId = urlParams.get("slackTeamId");
      const slackUserId = urlParams.get("slackUserId");

      const invite = urlParams.get("invite");

      // the check for "://" prevents arbitrary redirection (Open Redirect)
      const oauthRedirect = router.query.state?.redirect;
      const redirect = `${
        !!oauthRedirect && !oauthRedirect.includes("://") ? oauthRedirect : getSessionStorage("auth.redirect", "/")
      }${!!invite ? "?invite=1" : ""}`;

      removeSessionStorage("auth.redirect");

      const state = {
        flow: "default",
        redirect,
        slackTeamId,
        slackUserId,
        ref,
        utm,
      };

      reclaim.users.authRedirect(provider, storedUser?.email, state);
    },
    [router.query.state?.redirect, urlParams]
  );

  return (
    <LandingLayout title={title} loading={["init", "loading"].includes(status) || !!isAuthenticated}>
      <Container maxWidth="xl" disableGutters className={classes.root}>
        <div className={classes.main}>
          <Grid container item direction="row" className={classes.hero}>
            <Grid item xs={12} md={4} className={classes.heroImg}>
              <Image
                className={classes.img}
                src="/img/Reclaim Virtual Assistant.png"
                alt="Reclaim logo and smiley face"
                layout="intrinsic"
                width="320"
                height="312"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={8}
              lg={6}
              xl={false}
              direction="column"
              alignItems="stretch"
              alignContent="center"
              justifyContent="flex-start"
              className={classes.heroText}
            >
              <Grid item xs={12} md={10} className={classes.growBox}>
                {children}

                {!!displayLoginButton && (
                  <GoogleButton
                    label={`${loginButtonLabel} with Google`}
                    dark
                    onClick={() => handleClick("google")}
                    className={classes.button}
                  />
                )}

                {!!error && (
                  <Typography variant="body1" color="error" className={classes.body}>
                    {error}&nbsp; If you believe this to be an error, please contact support.
                  </Typography>
                )}
              </Grid>

              {!!officeNotice && (
                <Grid item xs={12} md={10} className={classes.fullWidth}>
                  <Typography variant="body2" className={classes.o365Callout}>
                    At this time, Reclaim only works on Google Calendar. Interested in Office 365?{" "}
                    <a href="mailto:hello@reclaim.ai?subject=Re:%20Office%20365%20Support%20for%20Reclaim&body=Hi%20Reclaim%20Team!%0D%0A%0D%0AI'm%20an%20Office%20365%20user%20and%20would%20love%20to%20stay%20updated%20when%20Reclaim%20works%20for%20O365%20calendars.%0D%0A%0D%0AThanks!">
                      Let&nbsp;us&nbsp;know
                    </a>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </Container>
      <Footer className={classes.footer} />
    </LandingLayout>
  );
};
